export const modals = () => {
  // Получаем элементы
  const modal = document.querySelector(".modal-call");
  const openModalBtn = document.querySelectorAll("[data-modal-call-trigger]");
  const closeModalBtn = document.querySelector(".modal-call__close");

  // Функция для открытия модального окна
  openModalBtn.forEach((item) => {
    item.onclick = function () {
      console.log("1");

      modal.style.display = "flex"; // Показать модальное окно
    };
  });

  // Функция для закрытия модального окна
  closeModalBtn.onclick = function () {
    console.log("2");

    modal.style.display = "none"; // Скрыть модальное окно
  };

  // Закрытие модального окна при клике вне его
  window.onclick = function (event) {
    console.log("3");

    // if (event.target == modal) {
    //   modal.style.display = "none"; // Скрыть модальное окно
    // }
  };
};
